import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Kiji from "@components/kiji-link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <section className='honbun'>
      <p>{`これは教材を進める上での基礎事項ですが、調べてわかることはご自分で調べて下さいね。例えば、「スタジオワンの詳しい操作」とか「特典ファイルの読み込み方」「プラグインの使い方」などです。`}<br parentName="p"></br>{`
`}{`これを見てくださっている方は、僕がググっても出てこないことを教えていることに価値を感じて、参加してくださったと思っています。ですので、わざわざググってわかる内容に教材の時間を割くよりも、ググっても絶対に分からないことを優先して話すべきだと思っています。（このような理由で、調べればわかる内容は、必要最低限のことしか触れていません）`}<br parentName="p"></br>{`
`}{`ですから、調べればわかることは、当たり前ですがご自分で調べる習慣をつけてください。中には、「iPhoneの使い方」「英語サイトの使い方」といった質問をしてこられる方もおられますが、これも教材の範囲ではありません。`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      